import React from "react";
import {Box, Typography, Rating, styled, useTheme} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const StyledRating = styled(Rating)(props => ({
  "&": {
    color: props.theme.palette.success.main,
    fontSize: "1rem"
  }
}))

interface ReviewCardProps {
  score: number;
  author: React.ReactNode;
  date: string;
}

export default function ReviewCard(props: React.PropsWithChildren<ReviewCardProps>) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        bgcolor: "#C3E2FF",
        width: "100%",
        maxWidth: "650px",
        padding: "40px",
        borderRadius: "40px",
        m: {xs: 2, md: 0},
        textAlign: {xs: "center", md: "left"}
      }}
    >
      <StyledRating readOnly value={props.score}/>
      <Typography variant="h4" sx={{mb: 2}}>{props.children}</Typography>
      <Typography variant="h3" sx={{mb: 1}}>{props.author}</Typography>
      {props.date && (
        <Typography
          variant="h5"
          color="success.main"
        >
          <CheckCircleIcon fontSize="inherit" sx={{mr: 1}} />
          Opinia zweryfikowana, Google Play, {props.date}
        </Typography>
      )}
    </Box>
  )
}